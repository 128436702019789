import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";

import UnividCard from "../generic/UnividCard";

import queryString from "query-string";

import styles from "./LogInPage.module.css";

import { VARIABLES } from "../../strings/variables.js";
import { reportError } from "../../logging/logger";

import axios from "axios";
import RegisterLogInInline from "./RegisterLogInInline";

class LogInPage extends Component {
  constructor(props) {
    super(props);

    const url = window.location.search.substring(1);
    this.params = queryString.parse(url);

    const addRoomSpacesQueryString = this.params.addRoomSpaces;
    const hasValidRoomSpacesToAdd =
      typeof addRoomSpacesQueryString === "string" &&
      addRoomSpacesQueryString.length > 0;
    this.roomSpacesToAdd = hasValidRoomSpacesToAdd
      ? addRoomSpacesQueryString.split(",").map((element) => element.trim())
      : null;

    const addRoomsQueryString = this.params.addRooms;
    const hasValidRoomsToAdd =
      typeof addRoomsQueryString === "string" && addRoomsQueryString.length > 0;
    this.roomsToAdd = hasValidRoomsToAdd
      ? addRoomsQueryString.split(",").map((element) => element.trim())
      : null;

    this.state = {
      redirectTo: null,
    };
  }

  async redirectWithAddedRoomOrRoomspaces() {
    // OBS: Rooms and roomspaces will be added in the Login component as well so this will add some redundancy?? (Move this to the login component i guess??)
    // Make sure the axios stuff finishes before redirect

    if (this.roomsToAdd != null || this.roomSpacesToAdd != null) {
      try {
        await axios.post(
          VARIABLES.apiUrl + "addfollowingroomsorroomspaces",
          {
            roomsToAdd: this.roomsToAdd,
            roomSpacesToAdd: this.roomSpacesToAdd,
          },
          {
            withCredentials: true,
          },
        );
      } catch (error) {
        reportError(error);
        console.log(
          "Something went wrong trying to add the rooms or roomspaces",
          error,
        );
      }
    }

    if (this.state.redirectTo == null) {
      this.setState({ redirectTo: "/" });
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.redirectWithAddedRoomOrRoomspaces();
    } else if (this.state.redirectTo != null) {
      this.setState({ redirectTo: null });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      // Did just authenticate
      this.redirectWithAddedRoomOrRoomspaces();
    }

    if (this.state.redirectTo != null) {
      this.setState({ redirectTo: null });
    }
  }

  render() {
    if (typeof this.state.redirectTo === "string") {
      return <Navigate to={this.state.redirectTo} />;
    } else {
      return (
        <div className={styles.MainContainer} style={{ overflow: "hidden" }}>
          <img
            alt=""
            className={styles.LoginPageBackground}
            src={
              "https://static.univid.io/platform/backgrounds/univid-world-footer.png"
            }
          />
          <div className={styles.LoginWrapper}>
            <a href="https://univid.io" className={styles.CompanyLogoContainer}>
              <div className={styles.CompanyLogo} />
            </a>
            <div className={styles.LoginContainer}>
              <UnividCard>
                <RegisterLogInInline initState={this.props.initState} />
              </UnividCard>
            </div>
            <div
              className={styles.UserContactInfo}
              style={{ marginTop: "20px" }}
            >
              For questions regarding accounts, email{" "}
              <a
                href="mailto:hello@univid.io"
                style={{
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                <b>hello@univid.io</b>
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    isAuthenticatedUnauth: state.user.isAuthenticatedUnauth,
    currentUser: state.user.currentUser,
    authFailed: state.user.authFailed,
  };
};

export default connect(mapStateToProps)(LogInPage);
